import React from "react"
import ImageTransform from "../ggfx-client/module/components/image-transform"
import {  companyNameSEO } from "../../../site/constants"

const GetGGFXImage = props => {
  return (
    <ImageTransform
      imagesources={props.imagesource && props.imagesource.url}
      renderer="pic-src"
      imagename={props.imagename}
      attr={{
        alt:
          props.imagesource && props.imagesource.alternativeText
            ? props.imagesource.alternativeText + companyNameSEO
            : props.fallbackalt + companyNameSEO,
      }}
      imagetransformresult={props.imagetransformresult}
      id={props.id}
      className={props.className}
    />
  )
}
export default GetGGFXImage
